<template>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Dodawanie producenta</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>


    <form class="space-y-8 divide-y divide-gray-200 mt-6">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
                
                <div class="hidden sm:block mb-3">
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Podstawowe informacje</p>
                </div>

                <!-- Name (select input) -->
                <div v-if="!isAddingNewManufacturer" class="space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Nazwa<span class="text-red-500">*</span></label>
                        <div class="mt-1 sm:mt-0 col-span-2">
                            <div class="flex rounded-md shadow-sm">
                                <Listbox class="w-full" as="div" v-model="manufacturer.name">
                                    <ListboxLabel class="block text-sm font-medium text-gray-700"></ListboxLabel>
                                    <div class="relative mt-1">
                                    <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <span class="block truncate">{{ loading ? 'Ładowanie' : (manufacturers.length > 0 ? (manufacturer.name || 'Wybierz producenta') : 'Brak dostępnych producentów')  }}</span>
                                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </ListboxButton>

                                    <transition v-if="manufacturers.length > 0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <ListboxOption as="template" v-for="item in manufacturers" :key="item.id" :value="item" v-slot="{ active, selected }">
                                            <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4']">
                                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{item}}</span>
                                            <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                            </span>
                                            </li>
                                        </ListboxOption>
                                        </ListboxOptions>
                                    </transition>
                                    </div>
                                </Listbox>  
                            </div>
                            <div v-if="errors.name" class="text-red-500 text-xs ml-1 mt-1">{{errors.name}}</div>
                        </div>
                    </div>
                </div>

                <!-- Name (text input) -->
                <div v-if="isAddingNewManufacturer" class="space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Nazwa<span class="text-red-500">*</span></label>
                        <div class="mt-1 sm:mt-0 col-span-2">
                            <div class="flex rounded-md shadow-sm mt-1">
                                <input v-model="manufacturer.name" type="text" maxlength="40" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <div v-if="errors.name" class="text-red-500 text-xs ml-1 mt-1">{{errors.name}}</div>
                        </div>
                    </div>
                </div>


                 <div class="relative flex items-start mb-4">
                    <div class="flex h-6 items-center">
                        <input v-model="isAddingNewManufacturer" id="adding-new-manufacturer" aria-describedby="adding-new-manufacturer" name="adding-new-manufacturer" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    </div>
                    <div class="ml-3 text-sm leading-6">
                        <label for="adding-new-manufacturer" class="font-medium text-gray-900">Dodaj producenta spoza produktów</label>
                    </div>
                </div>

                <div class="hidden sm:block mt-10 mb-3">
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Kontakt</p>
                </div>

                <!-- Email -->
                <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Email<span class="text-red-500">*</span></label>
                    <div class="mt-1 sm:mt-0 col-span-2">
                        <div class="flex rounded-md shadow-sm">
                            <input v-model="manufacturer.email" type="text" maxlength="100" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                        </div>
                        <div v-if="errors.email" class="text-red-500 text-xs ml-1 mt-1">{{errors.email}}</div>
                    </div>
                </div>

                <!-- Phone -->
                <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-b sm:border-gray-200 sm:py-5"> 
                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Telefon</label>
                    <div class="mt-1 sm:mt-0 col-span-2 flex items-center">
                        <div>
                            <Listbox v-model="manufacturer.phone.prefix" as="div">
                                <div class="relative">
                                <ListboxButton class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                    <span class="flex items-center">
                                        <span class="ml-1 block truncate">{{manufacturer.phone.prefix}}</span>
                                    </span>
                                    <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                        <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </ListboxButton>

                                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                    <ListboxOptions class="absolute z-10 mt-1 max-h-56  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <ListboxOption as="template" v-for="country in phonePrefixes" :key="country.name" :value="country.prefix" v-slot="{ active, selected }">
                                            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <div class="flex items-center">
                                                    <img :src="country.ref.flagURL" class="h-6 w-6 flex-shrink-0 rounded-full" />
                                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ country.prefix }} <span class="text-xs">({{country.name}})</span></span>
                                                </div>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                        </ListboxOption>
                                    </ListboxOptions>
                                </transition>
                                </div>
                            </Listbox>
                        </div>
                        <div class="flex items-center w-1/2 rounded-md shadow-sm ml-2">
                            <input v-model="manufacturer.phone.number" type="text" maxlength="12" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                        </div>
                    </div>
                </div>

                 <div class="hidden sm:block mt-10 mb-3">
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Adres</p>
                </div>

                <!-- Country -->
                <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Kraj</label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="w-1/2">
                            <Listbox as="div" v-model="manufacturer.location.country" >
                                <div class="relative">
                                    <ListboxButton :key="manufacturer.location.country" class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span class="flex items-center">
                                            <img :src="manufacturer.location.country?.flagURL" class="h-6 w-6 flex-shrink-0 rounded-full border-gray-300 border-2" />
                                            <span class="ml-3 block truncate">{{manufacturer.location.country?.translations?.pol?.common !== undefined ? manufacturer.location.country?.translations?.pol?.common :  manufacturer.location.country?.translations?.name?.common}}</span>
                                        </span>
                                        <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </ListboxButton>

                                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <ListboxOptions class="absolute z-10 mt-1 max-h-56  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            <ListboxOption as="template" v-for="country in countries" :key="country.translations.pol.common" :value="country" v-slot="{ active, selected }">
                                                <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                    <div class="flex w-full items-center">
                                                        <img :src="country.flagURL" class="h-6 w-6 flex-shrink-0 rounded-full" />
                                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{country.translations.pol.common !== undefined ? country.translations.pol.common :  country.name.common}}</span>
                                                    </div>

                                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                </li>
                                            </ListboxOption>
                                        </ListboxOptions>
                                    </transition>
                                </div>
                            </Listbox>
                        </div>
                    </div>
                </div>

                <!-- Address -->
                <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Adres</label>
                    <div class="mt-1 sm:mt-0 col-span-2">
                        <div class="flex rounded-md shadow-sm">
                            <input type="text" v-model="manufacturer.location.address" maxlength="50" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                        </div>
                    </div>
                </div>

                <!-- City -->
                <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Miasto</label>
                    <div class="mt-1 sm:mt-0 col-span-2">
                        <div class="flex rounded-md shadow-sm">
                            <input type="text" maxlength="50" v-model="manufacturer.location.city" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                        </div>

                    </div>
                    
                </div>

                <!-- Postcode -->
                <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-b sm:border-gray-200 sm:py-5">
                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Kod pocztowy</label>
                    <div class="mt-1 sm:mt-0 col-span-2">
                        <div class="w-1/2 flex rounded-md shadow-sm">
                            <input type="text" v-model="manufacturer.location.postcode" maxlength="12" class="flex-1 block w-1/2 py-2 px-4  focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                        </div>
                        <p v-if="manufacturer.location.country?.postalCode !== undefined" class="mt-2 text-xs text-gray-500">{{manufacturer.location.country?.postalCode?.format !== undefined ? 'Sugerowany format: '+ manufacturer.location.country?.postalCode?.format : 'Brak'}}</p>
                    </div>
                </div>

                <div class="pt-5">
                    <div class="flex justify-end select-none">
                        <button @click.prevent="addManufacturer()" :class="{'transition ease-in-out duration-300 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': true}">Dodaj producenta</button>
                    </div>
                </div>
            </div>            
        </div>
    </form>

</template>

<script>
    import { PlusSmIcon as PlusSmIconOutline, ChevronDownIcon, CheckIcon } from '@heroicons/vue/outline'
    import { HomeIcon } from '@heroicons/vue/solid'
    import { db } from "@/firebase/gfbconf.js";
    import EmptyState from '../components/EmptyState.vue';
    import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
    import axios from 'axios';
    import moment from 'moment';

    export default {
        name: "ManufacturersAdd",
        data() {
            return {
                isAddingNewManufacturer: false,
                loading: false,
                manufacturers: [],
                countries: [],
                selectedManufacturer: null,
                phonePrefixes: [],
                manufacturer: {
                    name: null,
                    email: null,
                    phone: {
                        prefix: null,
                        number: null,
                    },
                    location: {
                        country: null,
                        postcode: null,
                        city: null,
                        address: null
                    }
                },
                errors: {
                    name: null,
                    email: null,
                }
            }
        },
        components: {
            PlusSmIconOutline,
            HomeIcon,
            EmptyState,
            Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,
            ChevronDownIcon,
            CheckIcon
        },
        methods:
        {
            clearErrors() {
                this.errors.name = null,
                this.errors.email = null
            },
            async validate() {
                this.clearErrors()
                let validationSuccess = true
                const { name, email } = this.manufacturer

                if(!name || !name.length) {
                    this.errors.name = "Nazwa jest wymagana."
                    validationSuccess = false
                }

                if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                    this.errors.email = "Sprawdź poprawność adresu email."
                    validationSuccess = false
                }
                
                if(!email || !email.length) {
                    this.errors.email = "Email jest wymagany."
                    validationSuccess = false
                } 
                //check if a manufacturer with that email exists
                if((await db.collection("Manufacturers").where("email", "==", email).get()).docs.length > 0) {
                    this.errors.email = "Producent z takim adresem email już istnieje."
                    validationSuccess = false
                }
                if(!name || !name.length )
                {
                    this.errors.name = "Nazwa jest wymagana."
                }
                if((await db.collection("Manufacturers").where("name", "==", name).get()).docs.length > 0) {
                    this.errors.name = "Producent z taka nazwą już istnieje."
                    validationSuccess = false
                }

                return validationSuccess
            },
            async addManufacturer() {
                try {
                    if(!await this.validate()) return

                    this.$store.commit('setGlobalLoader',{
                        show: true,
                        head: "Dodawanie producenta..",
                        subheader: `Nie zamykaj tego okna.`,
                    });

                    const { name, email } = this.manufacturer
                    const { prefix, number } = this.manufacturer.phone
                    const { country, postcode, address, city } = this.manufacturer.location

                    let manufacturerDoc = await db.collection("Manufacturers").doc() 

                    const manufacturer = {
                        id: manufacturerDoc.id,
                        name,
                        email,
                        phone: {
                            full: `${prefix}${number === null ? '' : number}`,
                            prefix,
                            number
                        },
                        location: {
                            country: country.name.common,
                            city,
                            postcode,
                            address
                        },
                        meta: {
                            createdDate: moment().toDate(),
                            lastModificationDate: moment().toDate(),
                            createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                            userId: this.$store.state.userData.id
                        }
                    }

                    await manufacturerDoc.set(manufacturer)

                    //elastic search
                    const document = {
                        id: manufacturerDoc.id,
                        names: [name],
                        emails: [email],
                        phones: [manufacturer.phone.full],
                        countries: [country.name.common],
                        cities: [city],
                        postcodes: [postcode],
                        addresses: [address]
                    };

                    const elasticRes = await axios.post(`${this.$store.state.apiLink}/elastic/add`, {
                        index: "jan-art-manufacturers",
                        collection: "Manufacturers",
                        firestoreId: manufacturerDoc.id,
                        document: document
                    })

                    //set a success notification
                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Producent został dodany.",
                        subheader: `Producent został pomyślnie dodany do listy.`,
                        success: true
                    });

                    //redirect
                    this.$router.push("/dashboard/manufacturers/list");

                } catch(error) {
                    //set a fail notification
                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Nie udało się dodać producenta.",
                        subheader: `Nie udało się dodać producenta do listy.`,
                        success: false
                    });
                    console.log(error);
                }
                
            },
            async getManufacturersFromProducts(){
                this.loading = true
                this.manufacturers = []
                const products = await db.collection("Products").where("manufacturer", "!=", "").get()

                //get the manufacturers that are already added to the list
                const manufacturersInListDoc = await db.collection("Manufacturers").get()
                const manufacturersInList = []

                manufacturersInListDoc.forEach(doc => {
                    manufacturersInList.push(doc.data().name)
                })

                products.forEach(doc => {
                    const manufacturer = doc.data().manufacturer
                    //exclude refs and manufacturers that are already in the list
                    if(typeof manufacturer === "string" && !this.manufacturers.includes(manufacturer) && !manufacturersInList.includes(manufacturer)) {
                        this.manufacturers.push(manufacturer)
                    }
                })
                this.loading = false
            },
            async getCountries()
            {
                this.countries = [];
                const res = await axios.get(`${this.$store.state.apiLink}/data/getCountries`)
                if(res.data.status === 'SUCCESS')
                {
                    res.data.countries.forEach(country => {
                        this.countries.push(country);
                    });
                    
                    this.countries.forEach(country => {
                        const prefix = country.phonePrefixes[0];
                        const name = country.translations.pol.common != null ? country.translations.pol.common : country.name;
                        
                        if(country.name === "Poland")
                        {
                            this.manufacturer.phone.prefix = prefix;
                            this.manufacturer.location.country = country;
                        };
                        
                        // Check again to be sure
                        this.phonePrefixes.push({
                            prefix: prefix,
                            name: name,
                            ref: country
                        });
                    });
                }
            }
        },
        created() {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.products) {
                this.$router.push("/hub")
            }

            this.getManufacturersFromProducts()
            this.getCountries()
        },
    }
</script>